@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;900&display=swap);
/* ---------------
  Fonts
--------------- */

:root {
  /* Fonts */
  --raleway: 'Rubik', sans-serif;
  /* Light */
  --bg-black-900: #000000;
  --bg-black-100: #dddddd;
  --bg-black-50: #eff0f4;
  --bg-black-20: #dddddd;
  --bg-opacity: rgba(31, 23, 23, 0.5);
  --text-black-900: #000000;
  --text-black-700: #555555;
  --text-black-600: #666666;
  --text-black-300: #bbbbbb;
  --outer-shadow: 0.4rem 0.4rem 0.4rem #d0d0d0, -0.4rem -0.4rem 0.4rem #f8f8f8;
  --outer-shadow-0: 0 0 0 #d0d0d0, 0 0 0 #f8f8f8;
  --inner-shadow: inset 0.4rem 0.4rem 0.4rem #d0d0d0,
    inset -0.4rem -0.4rem 0.4rem #f8f8f8;
  --inner-shadow-0: inset 0 0 0 #d0d0d0, inset 0 0 0 #f8f8f8;
}

/* override custom css variables for dark theme */
body.dark {
  --bg-black-900: #ffffff;
  --bg-black-100: #212e36;
  --bg-black-50: #212e36;
  --bg-black-20: #cfcfcf93;
  --bg-opacity: rgba(43, 44, 47, 0.5);
  --text-black-900: #ffffff;
  --text-black-700: #ffffff;
  --text-black-600: #bbbbbb;
  --outer-shadow: 0.4rem 0.4rem 0.4rem #222327, -0.4rem -0.4rem 0.4rem #363636;
  --outer-shadow-0: 0 0 0 #222327, 0 0 0 #363636;
  --inner-shadow: inset 0.4rem 0.4rem 0.4rem #222327,
    inset -0.4rem -0.4rem 0.4rem #363636;
  --inner-shadow-0: inset 0 0 0 #222327, inset 0 0 0 #363636;
}

/* ---------------
  General Style
--------------- */
*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  outline: none;
  text-decoration: none;
}

*:not(i) {
  font-family: 'Rubik', sans-serif;
  font-family: var(--raleway);
  font-weight: 400;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  line-height: 1.5;
  overflow-x: hidden;
  background-color: #eff0f4;
  background-color: var(--bg-black-50);
}

body.stop-scrolling {
  overflow-y: hidden;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

/* Utilities */
.container {
  max-width: 120rem;
  width: 90%;
  margin: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.content-between {
  justify-content: space-between;
}

.content-around {
  justify-content: space-around;
}

.content-evenly {
  justify-content: space-evenly;
}

.content-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.outer-shadow {
  box-shadow: 0.4rem 0.4rem 0.4rem #d0d0d0, -0.4rem -0.4rem 0.4rem #f8f8f8;
  box-shadow: var(--outer-shadow);
}

.inner-shadow {
  box-shadow: inset 0.4rem 0.4rem 0.4rem #d0d0d0,
    inset -0.4rem -0.4rem 0.4rem #f8f8f8;
  box-shadow: var(--inner-shadow);
}

.hover-in-shadow {
  position: relative;
  z-index: 1;
}

.hover-in-shadow:hover {
  box-shadow: 0 0 0 #d0d0d0, 0 0 0 #f8f8f8;
  box-shadow: var(--outer-shadow-0);
}

.hover-in-shadow:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  z-index: -1;
}

.hover-in-shadow:hover:after {
  box-shadow: inset 0.4rem 0.4rem 0.4rem #d0d0d0,
    inset -0.4rem -0.4rem 0.4rem #f8f8f8;
  box-shadow: var(--inner-shadow);
}

.btn-1 {
  padding: 1rem 2.5rem;
  font-size: 1.6rem;
  font-weight: 50rem;
  color: var(--skin-color);
  background-color: transparent;
  line-height: 1.5;
  font-weight: bold;
  cursor: pointer;
  border-radius: 3rem;
  transition: all 0.3s ease;
  display: inline-block;
  border: none;
}

.btn-1:after {
  border-radius: 3rem;
}

.effect-wrap .effect {
  position: absolute;
  z-index: -1;
}

.effect-1 {
  width: 4rem;
  height: 4rem;
  /* border: 0.4rem solid #8a48ff; */
  border: 0.5rem solid var(--skin-color);
  left: 55%;
  top: 20%;
  -webkit-animation: spin 15s linear infinite;
          animation: spin 15s linear infinite;
}
.effect-2 {
  left: 10%;
  bottom: 10%;
  width: 4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-animation: topBounce 3s ease-in-out infinite;
          animation: topBounce 3s ease-in-out infinite;
}
.effect-2 div {
  height: 0.4rem;
  width: 0.4rem;
  background-color: var(--skin-color);
  margin: 0 0.3rem 0.8rem;
}

.effect-3 {
  height: 18rem;
  width: 18rem;
  border: 2.5rem solid var(--skin-color);
  border-radius: 50%;
  left: 40%;
  top: -6rem;
  -webkit-animation: leftBounce 6s ease-in-out infinite;
          animation: leftBounce 6s ease-in-out infinite;
}

.effect-4 {
  border-top: 3rem solid transparent;
  border-left: 3rem solid var(--skin-color);
  left: 35%;
  bottom: 25%;
  -webkit-animation: spin 12s linear infinite;
          animation: spin 12s linear infinite;
}

.effect-4:before {
  content: '';
  border-top: 3rem solid transparent;
  border-left: 3rem solid var(--skin-color);
  position: absolute;
  opacity: 0.5;
  left: -3.5rem;
  top: -2.5rem;
}

.effect-5 {
  height: 5rem;
  width: 5rem;
  left: 40%;
  bottom: 25%;
  /* right: 10%; */
  /* top: 30%; */
  display: flex;
  justify-content: space-between;
  border-radius: 50%;
  overflow: hidden;
  -webkit-animation: spin 15s linear infinite;
          animation: spin 15s linear infinite;
}

.effect-5 div {
  width: 0.2rem;
  background-color: var(--skin-color);
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes topBounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(2.5rem);
            transform: translateY(2.5rem);
  }
}

@keyframes topBounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(2.5rem);
            transform: translateY(2.5rem);
  }
}

@-webkit-keyframes leftBounce {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(2.5rem);
            transform: translateX(2.5rem);
  }
}

@keyframes leftBounce {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(2.5rem);
            transform: translateX(2.5rem);
  }
}

@-webkit-keyframes fadeInTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2.5rem);
            transform: translateY(-2.5rem);
  }
  50% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2.5rem);
            transform: translateY(-2.5rem);
  }
  50% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.section-title {
  padding: 0 1.5rem;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
  margin-bottom: 4rem;
}

.section-title h2 {
  display: inline-block;
  font-size: 3rem;
  font-weight: 700;
  color: #000000;
  color: var(--text-black-900);
  text-transform: uppercase;
  margin: 0;
}

.section-title h2:before {
  content: attr(data-heading);
  display: block;
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--skin-color);
}

/* .section.hide {
  display: none;
} */

.home-section {
  position: relative;
  top: 35px;
}

.home-section .full-screen {
  padding: 5rem 0;
  min-height: 100vh;
}

.home-section .home-text {
  flex: 0 0 60%;
  max-width: 60%;
}

.home-section .home-img {
  flex: 0 0 40%;
  max-width: 40%;
}

.home-section .home-text p {
  color: var(--text-black-300);
  font-size: 2.2rem;
  text-transform: uppercase;
  margin: 0;
  font-weight: 400;
}

.home-section .home-text h2 {
  font-size: 5rem;
  font-weight: 700;
  color: var(--text-black-900);
  margin: 0;
}

.home-section .home-text span {
  color: var(--skin-color);
  font-weight: 700;
}
.home-section .home-text h1 {
  font-size: 4rem;
  font-weight: 500;
  color: var(--text-black-600);
  margin: 0;
  text-transform: capitalize;
}

.home-section .home-text .btn-1 {
  margin-top: 3rem;
}

.home-section .home-img .img-box {
  /* max-width: 50rem; */
  display: block;
  margin: auto;
  /* border-radius: 50%; */
  /* padding: 1.5rem; */
}

.home-section .home-img .img-box img {
  width: 100%;
  /* border-radius: 50%; */
  /* border: 1rem solid transparent; */
}

/* --------
  Header 
-------- */
.header {
  padding: 1rem 0;
  position: fixed;
  background-color: var(--bg-black-50);
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}

.header .logo a {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  font-size: 3.4rem;
  height: 5rem;
  width: 5rem;
  color: var(--skin-color);
  border-radius: 50%;
  border: 0.3rem solid var(--skin-color);
  font-weight: 700;
  text-transform: uppercase;
}

.header .hamburger-btn {
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.header .hamburger-btn:after {
  border-radius: 50%;
}

.header .hamburger-btn span {
  display: block;
  height: 0.3rem;
  width: 2.5rem;
  background-color: var(--bg-black-900);
  position: relative;
}

.header .hamburger-btn span:before,
.header .hamburger-btn span:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background-color: var(--bg-black-900);
  left: 0;
}

.header .hamburger-btn span:before {
  top: -0.8rem;
}

.header .hamburger-btn span:after {
  top: 0.8rem;
}

.nav-menu {
  position: fixed;
  background-color: var(--bg-black-100);
  padding: 0 1.5rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
}

.fade-out-effect {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-black-50);
  z-index: -1;
  visibility: hidden;
}

.fade-out-effect.active {
  visibility: visible;
  z-index: 1000;
  -webkit-animation: fadeOut 0.3s ease-out;
          animation: fadeOut 0.3s ease-out;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.nav-menu.open {
  visibility: visible;
  opacity: 1;
  z-index: 999;
  transition: all 0.3s ease-in;
}

.nav-menu .close-nav-menu {
  height: 4rem;
  width: 4rem;
  display: block;
  font-size: 3.5rem;
  line-height: 3.5rem;
  border-radius: 50%;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  cursor: pointer;
  text-align: center;
  color: var(--text-black-600);
  transition: all 0.3s ease;
}

.nav-menu .close-nav-menu:after {
  border-radius: 50%;
}

.nav-menu .nav-menu-inner {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-menu ul {
  padding: 1.5rem;
}

.nav-menu ul li {
  display: block;
  margin-bottom: 2rem;
  text-align: center;
}

.nav-menu ul li:last-child {
  margin-bottom: 0;
}

.nav-menu ul li a {
  display: inline-block;
  font-size: 2.8rem;
  font-weight: 60rem;
  color: var(--text-black-600);
  padding: 0.5rem 3rem;
  text-transform: capitalize;
  border-radius: 3rem;
  transition: all 0.3s ease;
}

.nav-menu ul li a.active {
  color: var(--skin-color);
  font-weight: bold;
}
.nav-menu ul li a:after {
  border-radius: 3rem;
}

.nav-menu .copyright-text {
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 1.5rem;
  color: var(--text-black-600);
  -webkit-transform: translateY(50%) rotate(-90deg);
          transform: translateY(50%) rotate(-90deg);
  left: -5rem;
}

.about-section {
  padding: 8rem 0 0;
  min-height: 100vh;
}

.about-section .about-img {
  flex: 0 0 40%;
  max-width: 40%;
}

.about-section .about-img .img-box {
  padding: 1.5rem;
  border: 0.15rem solid transparent;
  border-radius: 2.5rem;
  text-align: center;
}

.about-section .about-info {
  padding: 2rem 5rem;
  flex: 0 0 60%;
  max-width: 60%;
}

.about-section .about-info p {
  font-size: 1.7rem;
  letter-spacing: 1px;
  line-height: 2.7rem;
  margin-bottom: 1.5rem;
  color: var(--text-black-600);
}

.about-section .about-info span {
  font-weight: 600;
}

.about-section .about-info .btn-1 {
  margin: 3rem 2rem 0 0;
}

.about-section .social-links {
  margin-top: 4rem;
  flex: 0 0 100%;
  text-align: center;
}

.about-section .social-links a {
  display: inline-block;
  height: 5rem;
  width: 5rem;
  font-size: 2rem;
  text-align: center;
  line-height: 5rem;
  color: var(--text-black-600);
  margin: 0 0.4rem;
  border-radius: 50%;
  transition: all 0.3s ease;
  margin: 0 1rem;
}

.about-section .social-links a i {
  line-height: 5rem;
}

.about-section .social-links a:after {
  border-radius: 50%;
}

.about-section .social-links a:hover {
  color: var(--skin-color);
}

.about-section .about-tabs {
  padding: 6rem 1.5rem 5rem;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
}

.about-section .about-tabs .tab-item {
  display: inline-block;
  margin: 0 0.5rem 1rem;
  padding: 0.5rem 1rem;
  border-radius: 3rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.6rem;
  color: var(--text-black-600);
  font-weight: 500;
  text-transform: capitalize;
}

.about-section .about-tabs .tab-item:hover:not(.active) {
  opacity: 0.6;
}

.about-section .about-tabs .tab-item.active {
  color: var(--skin-color);
}

.about-section .tab-content {
  flex: 0 0 100%;
  max-width: 100%;
  -webkit-animation: fadeInTop 0.5s ease;
          animation: fadeInTop 0.5s ease;
}

.about-section .skills {
  padding: 0 0 4rem;
}

.about-section .skills .skill-item {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(25% - 1.5rem);
  max-width: 25%;
  margin: 2rem 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-section .skills .skill-item img {
  width: 9rem;
  height: 9rem;
}

.about-section .skills .skill-item p {
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--text-black-600);
  text-transform: capitalize;
  margin: 2.5rem 0 1rem;
  letter-spacing: 1px;
}

.about-section .skills .skill-item .progress {
  height: 2rem;
  border-radius: 1rem;
  position: relative;
}

.about-section .skills .skill-item .progress-bar {
  background-color: var(--text-black-600);
  position: absolute;
  height: 0.6rem;
  left: 0.7rem;
  top: 0.7rem;
  border-radius: 1rem;
}

.about-section .skills .skill-item .progress-bar span {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--text-black-600);
  margin-top: -4rem;
}

.about-section .education,
.about-section .experience {
  padding-bottom: 8rem;
}

.about-section .timeline {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
}

.about-section .timeline:before {
  content: '';
  width: 0.5rem;
  height: 100%;
  left: 50%;
  top: 0;
  background-color: var(--bg-black-20);
  position: absolute;
}

.about-section .timeline .timeline-item {
  flex: 0 0 100%;
  max-width: 100%;
}

.about-section .timeline .timeline-item:nth-child(odd) {
  padding-right: calc(50% + 5rem);
  text-align: center;
}

.about-section .timeline .timeline-item:nth-child(even) {
  padding-left: calc(50% + 5rem);
  text-align: center;
}

.about-section .timeline .timeline-item-inner {
  padding: 3rem;
  border-radius: 0.5rem;
  position: relative;
}

.about-section .timeline .timeline-item-inner:before {
  content: '';
  position: absolute;
  width: 3.1rem;
  height: 0.1rem;
  background-color: var(--text-black-300);
  /* background-color: var(--bg-black-100); */
  top: 3.7rem;
  z-index: -1;
}

.about-section
  .timeline
  .timeline-item:nth-child(odd)
  .timeline-item-inner:before {
  right: -3.2rem;
}

.about-section
  .timeline
  .timeline-item:nth-child(even)
  .timeline-item-inner:before {
  left: -3rem;
}

.about-section .timeline .timeline-item-inner .icon {
  height: 3.8rem;
  width: 3.8rem;
  background-color: var(--bg-black-50);
  text-align: center;
  border: 0.1rem solid var(--bg-black-100);
  line-height: 4rem;
  border-radius: 50%;
  position: absolute;
  font-size: 1.6rem;
  color: var(--skin-color);
  top: 1.8rem;
}

.about-section .timeline .timeline-item:nth-child(odd) .icon {
  right: -7.2rem;
}

.about-section .timeline .timeline-item:nth-child(even) .icon {
  left: -6.8rem;
}

.about-section .timeline .timeline-item-inner span {
  font-weight: 50rem;
  color: var(--skin-color);
  display: block;
  margin: 0 0 1rem;
  text-transform: capitalize;
  font-weight: 600;
}

.about-section .timeline .timeline-item-inner h3 {
  font-size: 2rem;
  color: var(--text-black-700);
  font-weight: 600;
  margin: 0 0 0.5rem;
  text-transform: capitalize;
}

.about-section .timeline .timeline-item-inner h4 {
  font-size: 1.6rem;
  font-style: italic;
  color: var(--text-black-600);
  margin: 0;
}

.about-section .timeline .timeline-item-inner p {
  font-style: 1.6rem;
  color: var(--text-black-600);
  line-height: 2.6rem;
  margin: 1.5rem 0;
}

.service-section {
  padding: 8rem 0 2rem;
  /* min-height: 100vh; */
}

.service-section .service-item {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(33.33% - 1.5rem);
  max-width: 33.33%;
  margin-bottom: 2.5rem;
  display: flex;
  flex-wrap: wrap;
}

.service-section .service-item-inner {
  padding: 5rem 1.5rem;
  border-radius: 0.5rem;
  text-align: center;
  width: 100%;
  transition: all 0.2s ease;
}

.service-section .service-item-inner .icon {
  height: 6rem;
  width: 6rem;
  text-align: center;
  border-radius: 50%;
  transition: all 0.3s ease;
  display: block;
  margin: 0 auto 3rem;
  position: relative;
  color: var(--text-black-600);
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-section .service-item-inner .icon:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.service-section .service-item-inner:hover .icon {
  box-shadow: var(--inner-shadow-0);
}

.service-section .service-item-inner:hover .icon:after {
  box-shadow: var(--outer-shadow);
}

.service-section .service-item-inner:hover .icon {
  color: var(--skin-color);
}

.service-section .service-item-inner .icon .service-icon {
  font-size: 2.5rem;
  line-height: 6rem;
  transition: all 0.1s ease;
}

.service-section .service-item-inner h3 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--text-black-700);
  margin: 0 0 1rem;
  text-transform: capitalize;
}
.service-section .service-item-inner p {
  font-size: 1.6rem;
  color: var(--text-black-600);
  margin: 0;
  line-height: 2.6rem;
}

.portfolio-section {
  padding: 8rem 0 0;
}

.portfolio-section .section-title {
  margin-bottom: 4rem;
}

.portfolio-section .portfolio-filter {
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
  margin-bottom: 2rem;
}

.portfolio-section .filter-item {
  display: inline-block;
  margin: 0 0.5rem 1rem;
  padding: 0.5rem 1rem;
  border-radius: 3rem;
  cursor: pointer;
  font-weight: 500;
  color: var(--text-black-600);
  text-transform: capitalize;
  font-size: 1.6rem;
  transition: all 0.3s ease;
}

.portfolio-section .filter-item:hover:not(.active) {
  opacity: 0.6;
}

.portfolio-section .filter-item.active {
  color: var(--skin-color);
}

.portfolio-section .portfolio-item {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(33% - 1.5rem);
  max-width: 33.33%;
  padding-bottom: 2.5rem;
  display: flex;
  flex-wrap: wrap;
}

.portfolio-section .portfolio-item.hide {
  display: none;
}

.portfolio-section .portfolio-item.show {
  display: block;
  -webkit-animation: fadeinTop 0.5s ease;
          animation: fadeinTop 0.5s ease;
}

.portfolio-section .portfolio-item-inner {
  padding: 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 100%;
}

.portfolio-section .portfolio-item-img img {
  border-radius: 0.5rem;
  width: 100%;
  transition: all 0.3s ease;
}

.portfolio-section .portfolio-item-inner:hover .portfolio-item-img img {
  -webkit-transform: translateY(-25px);
          transform: translateY(-25px);
}

.portfolio-section .portfolio-item-img {
  position: relative;
}

.portfolio-section .portfolio-item-img .view-project {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  color: var(--skin-color);
  transition: all 0.3s ease;
  position: absolute;
  left: 0;
  bottom: -4px;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  z-index: -1;
  opacity: 0;
  /* display: none; */
}

.portfolio-section .portfolio-item-inner:hover .view-project {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  opacity: 1;
}

.portfolio-section .portfolio-item-details {
  display: none;
}

.portfolio-section .portfolio-item-inner p {
  font-size: 1.6rem;
  margin: 1rem 0 0;
  color: var(--text-black-600);
  text-transform: capitalize;
}

/* Portfolio Popup */

.portfolio-popup {
  background-color: var(--bg-black-50);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.portfolio-popup.open {
  visibility: visible;
  opacity: 1;
}

.portfolio-popup .pp-loader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1005;
  background-color: var(--bg-opacity);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
}

.portfolio-popup .pp-loader.active {
  visibility: visible;
  opacity: 1;
}
.portfolio-popup .pp-loader div {
  height: 40px;
  width: 40px;
  border: 3px solid var(--skin-color);
  border-radius: 50%;
  border-right: 3px solid transparent;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

.portfolio-popup .pp-details {
  max-width: 1350px;
  width: 85%;
  margin: auto;
  width: calc(85% + 30px);
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.5s ease;
}

.portfolio-popup .pp-details.active {
  opacity: 1;
}

.portfolio-popup .pp-details-inner {
  padding: 30px 15px;
}

.portfolio-popup .pp-title h2 {
  font-size: 28px;
  color: var(--text-black-900);
  text-transform: capitalize;
  font-weight: 600;
  margin: 0 0 5px;
}

.portfolio-popup .pp-title p {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-black-600);
  margin: 0 0 15px;
  border-bottom: 1px solid var(--bg-black-100);
  padding-bottom: 10px;
}

.portfolio-popup .pp-title p span {
  font-weight: 400;
  text-transform: capitalize;
}

.portfolio-popup .pp-project-details h3 {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-black-700);
  margin: 0 0 15px;
  text-transform: capitalize;
}

.portfolio-popup .description {
  flex: 0 0 65%;
  max-width: 65%;
  padding-right: 15px;
}

.portfolio-popup .description p {
  font-size: 16px;
  color: var(--text-black-600);
  line-height: 26px;
  margin: 0;
}

.portfolio-popup .info {
  flex: 0 0 35%;
  max-width: 35%;
}

.portfolio-popup .info ul li {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  color: var(--text-black-600);
  font-size: 16px;
}

.portfolio-popup .info ul li span {
  font-weight: 400;
}

.portfolio-popup .info ul li a {
  color: var(--skin-color);
}

.portfolio-popup .info ul li:last-child {
  margin-bottom: 0;
}

.portfolio-popup .separator {
  height: 1px;
  width: 100%;
  display: block;
  background-color: var(--bg-black-100);
}

.portfolio-popup .pp-img {
  max-width: 100%;
  width: auto;
  height: auto;
  padding: 10px;
  border-radius: 5px;
}

.portfolio-popup .pp-main {
  min-height: 100vh;
  max-width: 1350px;
  width: 85%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio-popup .pp-main-inner {
  padding: 70px 0 50px;
  position: relative;
}

.portfolio-popup .pp-project-details-btn {
  position: absolute;
  left: 0;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  color: var(--skin-color);
  top: 15px;
  cursor: pointer;
  padding: 0 15px;
  height: 40px;
  line-height: 40px;
  transition: all 0.3s ease;
}

.portfolio-popup .pp-project-details-btn i {
  margin-left: 4px;
}

.portfolio-popup .pp-project-details-btn:after {
  border-radius: 30px;
}

.portfolio-popup .pp-close {
  position: absolute;
  right: 0;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  top: 15px;
  text-align: center;
  line-height: 35px;
  font-size: 35px;
  color: var(--text-black-600);
  cursor: pointer;
  transition: all 0.3s ease;
}

.portfolio-popup .pp-close:after {
  border-radius: 50%;
}

.portfolio-popup .pp-counter {
  position: absolute;
  right: 0;
  height: 40px;
  line-height: 40px;
  bottom: 5px;
  font-size: 16px;
  color: var(--text-black-600);
}

.portfolio-popup .pp-prev,
.portfolio-popup .pp-next {
  position: fixed;
  top: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  z-index: 1010;
  color: var(--text-black-900);
  transition: ease 0.3s ease;
  opacity: 0.55;
}

.portfolio-popup .pp-prev:hover,
.portfolio-popup .pp-next:hover {
  opacity: 1;
}

.portfolio-popup .pp-prev i,
.portfolio-popup .pp-next i {
  line-height: 40px;
}

.portfolio-popup .pp-prev i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.portfolio-popup .pp-prev {
  left: 15px;
}

.portfolio-popup .pp-next {
  right: 15px;
}

.contact-section {
  padding: 8rem 0 8rem;
}

.contact-section .contact-item {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(25% - 2.5rem);
  max-width: 25%;
  border-radius: 5px;
  transition: all 0.5s ease;
  cursor: pointer;
  margin-bottom: 3rem;
}

.contact-section .contact-item:hover {
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
}

.contact-section .contact-item-inner {
  padding: 30px 15px;
  text-align: center;
  border-radius: 5px;
  width: 100%;
}

.contact-section .contact-item a {
  width: 100%;
  height: 100%;
}
.contact-section .contact-item-inner .contact-icon {
  font-size: 40px;
  color: var(--skin-color);
}

.contact-section .contact-item-inner span {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-black-700);
  margin: 15px 0 10px;
  text-transform: capitalize;
}

.contact-section .contact-item-inner p {
  font-size: 16px;
  color: var(--text-black-600);
  line-height: 26px;
  margin: 0;
  word-break: break-word;
}

.contact-section .contact-form {
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 50px;
}

.contact-section .w-50 {
  padding: 0 15px;
  flex: 0 0 50%;
  max-width: 50%;
}

.contact-section .input-group {
  margin: 0 0 25px;
  transition: all 0.3s ease;
  border-radius: 30px;
}

.contact-section .input-group:after {
  border-radius: 30px;
}

.contact-section .input-group .input-control {
  height: 45px;
  display: block;
  width: 100%;
  border-radius: 30px;
  border: none;
  background-color: transparent;
  font-size: 16px;
  padding: 0 15px;
  color: var(--text-black-700);
}

.contact-section .input-group textarea.input-control {
  height: 180px;
  padding-top: 15px;
  resize: none;
}

.contact-section .submit-btn {
  flex: 0 0 100%;
  max-width: 100%;
  text-align: right;
  padding: 0 15px;
}

.style-switcher {
  position: fixed;
  right: 0;
  top: 7.7rem;
  padding: 1.5rem;
  width: 20rem;
  z-index: 101;
  border-radius: 5px;
  transition: all 0.3s ease;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  background-color: var(--bg-black-50);
}

.style-switcher.open {
  -webkit-transform: translateX(-1.5rem);
          transform: translateX(-1.5rem);
}

.style-switcher .s-icon {
  position: absolute;
  height: 4rem;
  width: 4rem;
  text-align: center;
  font-size: 2rem;
  right: 100%;
  color: var(--text-black-900);
  margin-right: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50%;
  background-color: var(--bg-black-50);
}

.style-switcher .s-icon i {
  line-height: 4rem;
}

.style-switcher .s-icon:after {
  border-radius: 50%;
}

.style-switcher .style-switcher-toggler {
  top: 0;
}

.style-switcher .day-night {
  top: 5.5rem;
}

.style-switcher h4 {
  margin: 0 0 1rem;
  color: var(--text-black-700);
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.style-switcher .colors {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.style-switcher .colors span {
  display: inline-block;
  height: 3rem;
  width: 3rem;

  border-radius: 50%;
  cursor: pointer;
}

.style-switcher .color-1 {
  background-color: #1fc586;
}

.style-switcher .color-2 {
  background-color: #ffb400;
}

.style-switcher .color-3 {
  background-color: #2196f3;
}

.style-switcher .color-4 {
  background-color: #8e60e9;
}

.style-switcher .color-5 {
  background-color: #ec3853;
}

.footer {
  padding: 0 0 4rem;
}

.footer hr {
  width: 100%;
  padding-bottom: 0.3rem;
  margin-bottom: 4rem;
  border: none;
  background-color: var(--skin-color);
}

.footer .copy {
  width: 100%;
  font-size: 1.8rem;
  text-align: center;
  color: var(--bg-black-900);
}

@media (max-width: 991px) {
  .portfolio-section .portfolio-item,
  .service-section .service-item {
    flex: 0 0 47%;
    max-width: 47%;
  }

  .home-section .home-text {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .home-section .home-img {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .home-section .home-text h2 {
    font-size: 4.5rem;
  }

  .home-section .home-text h1 {
    font-size: 3.5rem;
  }
}

@media (max-width: 768px) {
  .contact-section .w-50,
  .portfolio-popup .description,
  .portfolio-section .portfolio-item,
  .service-section .service-item,
  .about-section .about-img,
  .about-section .about-info,
  .home-section .home-text,
  .home-section .home-img {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contact-section .contact-item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 2rem);
    max-width: 50%;
  }

  .about-section .about-img {
    flex: 0 0 70%;
    max-width: 70%;
  }

  .about-section .skills .skill-item {
    flex: 0 0 33%;
    max-width: 33%;
  }

  .home-section .home-text {
    order: 2;
  }

  .contact-section .submit-btn,
  .home-section .home-text {
    text-align: center;
  }

  .home-section .full-screen {
    padding: 100px 0;
  }

  .home-section .home-img .img-box {
    margin-bottom: 40px;
    max-width: 300px;
  }

  .effect-1 {
    display: none;
  }

  .effect-2 {
    left: 5%;
  }

  .effect-3 {
    height: 15rem;
    width: 15rem;
    left: 3%;
    border: 1.8rem solid var(--skin-color);
    top: -5rem;
  }

  .effect-4 {
    left: 80%;
    top: 48%;
    display: none;
  }

  .effect-5 {
    display: none;
  }

  .about-section .about-img {
    padding-bottom: 30px;
  }
  .about-section .timeline .timeline-item:nth-child(odd) {
    padding-right: 15px;
    text-align: left;
    padding-left: 75px;
  }

  .about-section .timeline .timeline-item:nth-child(even) {
    padding-left: 75px;
  }

  .about-section .timeline:before {
    left: 35px;
  }

  .about-section .timeline .timeline-item:nth-child(odd) .icon {
    right: auto;
    left: -60px;
  }

  .about-section .timeline .timeline-item:nth-child(even) .icon {
    left: -60px;
  }

  .about-section .timeline .timeline-item {
    margin-bottom: 40px;
  }

  .about-section .timeline .timeline-item:last-child {
    margin-bottom: 0;
  }

  .about-section
    .timeline
    .timeline-item:nth-child(odd)
    .timeline-item-inner:before {
    left: -30px;
    right: auto;
  }

  .portfolio-popup .description {
    margin-bottom: 20px;
  }
}

@media (max-width: 574px) {
  .contact-section .contact-item {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .about-section .skills .skill-item {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .about-section .about-info {
    padding: 0;
  }

  .about-section .about-img {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .home-section {
    top: 0;
  }

  .home-section .home-img .img-box {
    /* margin-bottom: 40px; */
    width: 100%;
  }

  .home-section .home-text h2 {
    font-size: 32px;
  }

  .home-section .home-text h1 {
    font-size: 30px;
  }

  .home-section .home-text p {
    font-size: 25px;
  }
}

@media (max-width: 375px) {
  .home-section .home-text h2 {
    font-size: 25px;
  }

  .home-section .home-text h1 {
    font-size: 23px;
  }

  .home-section .home-text p {
    font-size: 20px;
  }
}

